
<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}">
        <div class="container g-0">
            <nav id="navbar-example2"
                class="navbar navbar-expand-lg navbar-light"
                [class.active]="classApplied">
                <a class="navbar-brand d-none ms-3" href="#home"
                    (click)="scrollToSection($event, 'home')">
                    <img src="../assets/images/compract-logo-edited.png"
                        style="height: 8vh; width:28vw; " alt="logo">
                </a>

                <button class="navbar-toggler" type="button"
                    (click)="toggleClass()">
                    <span class="burger-menu me-5">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu "
                    id="navbarSupportedContent">
                    <div class="logo" style="cursor: pointer;">
                        <a href="#home" (click)="scrollToSection($event, 'home')">
                          <img
                            src="../assets/images/compract-logo-edited.png"
                            class="d-none d-lg-block" 
                            style="height: 8vh; width: 13vw;"
                            alt="logo"
                          />
                        </a>
                      </div>
                      
                      
                    <ul class="navbar-nav text-left " style="cursor: pointer;"
                        style="margin-left: 20%;">
                        <li class="nav-item"><a href="#home"
                                (click)="scrollToSection($event, 'home')"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" href="#about"
                                (click)="scrollToSection($event, 'about')"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>

                        <li class="nav-item"><a href="#services"
                                (click)="scrollToSection($event, 'services')"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Services</a>
                        </li>
                        <li class="nav-item"><a href="#products"
                            (click)="scrollToSection($event, 'products')"
                            class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Products</a>
                    </li>

                        <li class="nav-item"><a href="#blog"
                                (click)="scrollToSection($event, 'blog')"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Blog</a></li>
                        <li class="nav-item"><a href="#contact"
                                (click)="scrollToSection($event, 'contact')"
                                class="nav-link">Contact </a></li>
                    </ul>
                </div>
                <div class="nav-btn">
                    <a href="#home" (click)="scrollToSection($event, 'home')"
                        class="box-btn">Get Started</a>
                </div>
            </nav>
        </div>
    </div>

</div>

<!-- Slider -->
<div data-bs-target="#navbar-example2">
    <section id="home" class="slider-area-2">
        <div class="home-slider">
            <owl-carousel-o [options]="homeSlides">
                <ng-template carouselSlide>
                    <div class="single-slider single-slider-bg-1">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="slider-content one">
                                                <h1>Awesome App For Your Modern
                                                    Lifestyle</h1>
                                                <p>Experience the future of
                                                    convenience with our app
                                                    designed for your
                                                    fast-paced, modern life.</p>
                                                <div
                                                    class="slider-btn text-center">
                                                    <a href="#contact"
                                                        (click)="scrollToSection($event, 'contact')"
                                                        class="box-btn">Let’s
                                                        Talk!</a>
                                                    <a href="#services"
                                                        (click)="scrollToSection($event, 'services')"
                                                        class="box-btn">Know
                                                        More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slider single-slider-bg-2">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="slider-content one">
                                                <h1>Digital Agency &
                                                    Marketing</h1>
                                                <p>We help businesses grow
                                                    online with expert digital
                                                    strategies and creative
                                                    marketing solutions. </p>
                                                <div
                                                    class="slider-btn text-center">
                                                    <a href="#contact"
                                                        (click)="scrollToSection($event, 'contact')"
                                                        class="box-btn">Let’s
                                                        Talk!</a>
                                                    <a href="#services"
                                                        (click)="scrollToSection($event, 'services')"
                                                        class="box-btn">Know
                                                        More!</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-slider single-slider-bg-3">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div id="scrollspyabout" class="container">
                                    <div class="row align-items-center">
                                        <div class="col-lg-12 text-center">
                                            <div class="slider-content one">
                                                <h1>Make Real-Life Connections
                                                    With IT</h1>
                                                <p>Our services enhance everyday
                                                    experiences through smart
                                                    tech and user-friendly
                                                    applications. Connect with
                                                    us to make technology work
                                                    seamlessly in your daily
                                                    life.</p>
                                                <div
                                                    class="slider-btn text-center">
                                                    <a href="#contact"
                                                        (click)="scrollToSection($event, 'contact')"
                                                        class="box-btn">Let’s
                                                        Talk!</a>
                                                    <a href="#services"
                                                        (click)="scrollToSection($event, 'services')"
                                                        class="box-btn border-btn">Know
                                                        More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>

    </section>

    <!-- about section -->
    <section id="about" class="home-company-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="company-content">
                        <div class="company-tittle " style="text-justify: center;">
                            <span class="d-flex justify-content-center">About Us</span>
                            <!-- <h2>Innovative It Helping Service All Over the
                                World</h2> -->
                            <p style="text-justify: center"><b> Welcome to Compract Solutions</b>, where innovation meets excellence. At Compract Solutions (003645203-K), we are dedicated to delivering top-tier technology solutions that empower businesses to thrive in an ever-evolving digital landscape. Our mission is to provide cutting-edge software and IT services tailored to meet the unique needs of our clients, driving efficiency, productivity, and growth.
                            </p>
                            <h5>Our Story
                            </h5>
                            <p style="text-justify: center">Founded with a vision to revolutionize the tech industry, Compract Solutions has grown into a leading provider of comprehensive technology solutions. Our team of experts brings together extensive experience, technical expertise, and a passion for innovation. We pride ourselves on our ability to stay ahead of industry trends and deliver solutions that not only meet but exceed our clients' expectations.
                            </p>
                            <h5>Partnership with Bhojsoft Solutions</h5>
                            <p style="text-justify: center;">In our commitment to delivering exceptional service and cutting-edge solutions, we are proud to be technical partner with Bhojsoft Solutions. This strategic alliance enhances our capabilities and broadens our technical deliveriables and service offerings, combining our strengths to provide unparalleled value to our clients.
                            </p>
                        </div>
                        <a href="#services"
                            (click)="scrollToSection($event, 'services')"
                            class="box-btn">Know More</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="company-img">
                        <img src="assets/images/about-img-2.jpg"
                            alt="company" />
                    </div>
                </div>
            </div>
        </div>
        <a class="h-0 w-0" href="https://wa.me/+60186655744" target="_blank"><i
                class="fa-brands fa-whatsapp whatsapp-icon"></i></a>
    </section>

    <!-- Our Vision -->

    <div class="info-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-info">
                        <div class="info-img">
                            <img src="assets/images/info/i1.jpg" alt="info" />
                        </div>

                        <div class="content">
                            <h3><i class="flaticon-info"></i> About Us</h3>
                            <div class="arrow">
                                <a href="#about"
                                    (click)="scrollToSection($event, 'about')"><i
                                        class="flaticon-next-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-info">
                        <div class="info-img">
                            <img src="assets/images/info/i2.jpg" alt="info" />
                        </div>

                        <div class="content">
                            <h3><i class="flaticon-support"></i> Our Vision</h3>
                            <div class="arrow">
                                <a href="#about"
                                    (click)="scrollToSection($event, 'about')">
                                    <i class="flaticon-next-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="single-info si-30">
                        <div class="info-img">
                            <img src="assets/images/info/i3.jpg" alt="info" />
                        </div>

                        <div class="content">
                            <h3><i class="flaticon-goal"></i>Our Goal</h3>
                            <div class="arrow">
                                <a href="#about"
                                    (click)="scrollToSection($event, 'about')"><i
                                        class="flaticon-next-1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Smart Services -->
    <section id="services" class="home-service-area pb-70">
        <div class="container">
            <div class="section-title">
                <span>Smart Services</span>
                <h2>Compract Solutions Provide All Kind of Tech Solutions</h2>
                <p> Our expert team excels in crafting innovative software that
                    drives efficiency and growth. Trust us to transform your
                    ideas into cutting-edge technological solutions.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s1.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3>Visual Design</h3>
                            <p>Our visual design transforms ideas into stunning,
                                user-friendly, and engaging interfaces.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s2.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3>Development</h3>
                            <p>Our development process merges innovation with
                                precision to create powerful, bespoke software
                                solutions</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s3.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3>QA Testing</h3>
                            <p>QA testing ensures flawless software by
                                identifying and fixing all potential issues</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s4.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3>IT Management</h3>
                            <p>IT management optimizes systems and processes for
                                efficient and secure operations</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s5.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3> Cyber Security</h3>
                            <p>Cyber security protects your data from threats
                                with advanced and proactive measures</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-service">
                        <div class="service-img">
                            <img src="assets/images/service/s6.png"
                                alt="service" />
                        </div>

                        <div class="service-content">
                            <h3> Wireless Connectivity</h3>
                            <p>Wireless connectivity provides seamless and
                                reliable network access for all your devices</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- products -->
    <section id="products" class="feature-area bg-color ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="contnet">
                        <div class="feature-tittle">
                            <span>Products</span>
                            <h2>Lotus: Corporate Learning Management System</h2>
                            <p>Lotus is a cutting-edge Corporate Learning Management System designed to streamline employee training and development. By offering a robust platform for course creation, tracking, and reporting, Lotus enables organizations to enhance workforce skills and boost productivity. With its intuitive interface and customizable features, Lotus empowers businesses to deliver engaging learning experiences tailored to their unique needs, fostering a culture of continuous improvement and professional growth.</p>
                        </div>
                        <ul>
                            <li><i class="flaticon-correct"></i>Revolutionizing Corporate Training</li>
                            <li><i class="flaticon-correct"></i> Empowering Learning and Development</li>
                            <li><i class="flaticon-correct"></i> Intuitive Design for All Learners</li>
                            <li><i class="flaticon-correct"></i>Adapting to Your Organization’s Needs</li>
                            <li><i class="flaticon-correct"></i> Measuring Success in Employee Development</li>
                        </ul>
                        <a  href="#contact"
                        (click)="scrollToSection($event, 'contact')" class="box-btn">Lets Talk!</a>
                    </div>
                </div>
    
                <div class="col-lg-6">
                    <div class="">
                        <!-- <img src="assets/images/feature-img.png" alt="feature" /> -->
                        <img class="rounded" src="assets/images/solutions-details/2.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </section>




    <!-- Working Process -->
    <section class="home-process-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span>Working Process</span>
                <h2>We are popular because of our way of working</h2>
                <p>At Compract Solutions, we follow a meticulous development
                    process that ensures quality and efficiency at every stage.
                </p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-process">
                        <div class="icon">
                            <img src="assets/images/process/p1.png"
                                alt="process" />
                            <span><img src="assets/images/process/golden.png"
                                    alt="shape" /></span>
                        </div>

                        <div class="content">
                            <h3>Research Product</h3>
                            <p>We conduct thorough research to understand and
                                refine your product requirements.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-process">
                        <div class="icon">
                            <img src="assets/images/process/p2.png"
                                alt="process" />
                            <span class="pro-span"><img
                                    src="assets/images/process/golden.png"
                                    alt="shape" /></span>
                        </div>

                        <div class="content">
                            <h3>User Testing</h3>
                            <p>User testing ensures the product meets
                                expectations and provides a smooth
                                experience.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-process">
                        <div class="icon">
                            <img src="assets/images/process/p3.png"
                                alt="process" />
                            <span><img src="assets/images/process/golden.png"
                                    alt="shape" /></span>
                        </div>

                        <div class="content">
                            <h3>Development</h3>
                            <p>Our development phase builds high-quality,
                                customized solutions based on thorough
                                planning.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-process">
                        <div class="icon">
                            <img src="assets/images/process/p4.png"
                                alt="process" />
                        </div>

                        <div class="content">
                            <h3>Product Handover</h3>
                            <p>We ensure a smooth product handover with
                                comprehensive documentation and support.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Choose Compract Solutions -->
    <section class="home2-choose-area pt-100 pb-70">
        <div class="container ">
            <div class="section-title">
                <span>Choose Compract Solutions</span>
                <h2>We Achieved People’s Trust by Our Great Service</h2>
                <p>Choose Compract Solutions for expert software development
                    that blends innovation with precision. Our tailored
                    solutions, from research to handover, ensure your business
                    achieves its goals efficiently and effectively.</p>
            </div>

            <div class="row ">
                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose">
                        <div class="icon">
                            <i class="flaticon-friends"></i>
                        </div>
                        <h3 class="cursor-pointer">Understanding</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose">
                        <div class="icon">
                            <i class="flaticon-award"></i>
                        </div>
                        <h3 class="cursor-pointer">Best Quality</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose">
                        <div class="icon">
                            <i class="flaticon-chip"></i>
                        </div>
                        <h3 class="cursor-pointer">Technology</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose">
                        <div class="icon">
                            <i class="flaticon-customer-service"></i>
                        </div>
                        <h3 class="cursor-pointer">Support 24/7</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose">
                        <div class="icon">
                            <i class="flaticon-like"></i>
                        </div>
                        <h3 class="cursor-pointer">Expert Team</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-4">
                    <div class="single-choose cursor-pointer">
                        <div class="icon">
                            <i class="flaticon-coin"></i>
                        </div>
                        <h3 class="cursor-pointer">Price Oriented</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Updated Technology -->
    <section class="technology-area ptb-100 bg-color">
        <div class="container">
            <div class="row align-items-center choose-c">
                <div class="col-lg-6">
                    <div class="choose-img">
                        <img src="assets/images/choose-img1.jpg" alt="choose" />

                        <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="technology-content">
                        <h2>Updated Technology</h2>
                        <p>At Compract Solutions, we stay at the forefront of
                            technology to provide cutting-edge software
                            solutions. Our commitment to updating technology
                            ensures that your systems leverage the latest
                            advancements, enhancing performance, security, and
                            user experience.</p>
                        <p>By continuously integrating modern tools and
                            methodologies, we deliver innovative solutions that
                            keep your business competitive and agile in a
                            rapidly evolving tech landscape.
                        </p>
                        <a href="#contact"
                            (click)="scrollToSection($event, 'contact')"
                            class="box-btn">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Video Popup -->
    <!-- <div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div> -->

    <!-- Testimonials -->
    <section class="client-area ptb-100 bg-color">
        <div class="container">
            <div class="section-title">
                <span>Testimonials</span>
                <h2>What Our Client’s Say</h2>
                <p>Working with Compract Solutions has been a game-changer for
                    our business. Their innovative software solutions
                    transformed our operations, enhancing efficiency and driving
                    growth. </p>
            </div>
            <div class="client-wrap">
                <owl-carousel-o [options]="clientWrap">
                    <ng-template carouselSlide>
                        <div class="single-client">
                            <!-- <img src="assets/images/client/1.jpg" alt="img"> -->
                            <p> Their dedication to understanding our unique
                                needs and providing innovative solutions has
                                been outstanding. We highly recommend their
                                services to any business seeking reliable and
                                advanced technology solutions.</p>
                            <h3>Steven Jony</h3>
                            <span>CEO of Company</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-client">
                            <!-- <img src="assets/images/client/2.jpg" alt="img"> -->
                            <p>Choosing Compract Solutions was a smart decision
                                for our company. Their expert team developed a
                                customized software solution that perfectly met
                                our requirements. The quality of their work and
                                their commitment to customer satisfaction truly
                                set them apart in the industry.</p>
                            <span>Company Founder</span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </section>
    <!-- blog post  -->
    <section id="blog" class="home-blog-area pt-100 pb-100 bg-color">
        <div class="container">
            <div class="section-title">
                <span>Blog Post</span>
                <h2>Our Regular Blogs</h2>
                <p>Discover how emerging tech trends and groundbreaking
                    innovations are shaping the future, offering new
                    opportunities and transformative solutions for
                    businesses.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="blog-img">
                            <a href="#blog"
                                (click)="scrollToSection($event, 'blog')"><img
                                    src="assets/images/blog/1.jpg"
                                    alt="blog"></a>
                        </div>

                        <div class="content">
                            <ul>
                                <li>17 sept 2024</li>
                                <li><a href="#blog"
                                        (click)="scrollToSection($event, 'blog')">By
                                        Admin</a></li>
                            </ul>
                            <a href="#blog"
                                (click)="scrollToSection($event, 'blog')"><h3>Harnessing
                                    AI and Machine Learning for Business
                                    Transformation</h3></a>
                            <p>
                                AI and machine learning transform businesses,
                                boosting efficiency and growth.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog">
                        <div class="blog-img">
                            <a><img src="assets/images/blog/5.jpg"
                                    alt="blog"></a>
                        </div>

                        <div class="content">
                            <ul>
                                <li>15 Aug 2024</li>
                                <li><a href="#home"
                                        (click)="scrollToSection($event, 'home')">By
                                        Admin</a></li>
                            </ul>
                            <a><h3>The Future of Cloud Computing: Trends and
                                    Best Practices</h3></a>
                            <p>This blog provides a comprehensive overview of
                                the latest trends in cloud computing and their
                                impact on businesses. </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="single-blog">
                        <div>
                            <a><img src="assets/images/blog/3.jpg"
                                    alt="blog"></a>
                        </div>

                        <div class="content">
                            <ul>
                                <li>10 April 2024</li>
                                <li><a>By Admin</a></li>
                            </ul>
                            <a><h3>Cybersecurity in the Digital Age</h3></a>
                            <p> This blog covers essential practices for
                                implementing robust security measures, including
                                threat detection, data encryption, and incident
                                response planning. </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="blog-btn text-center cursor-pointer">
                <p>We Have More Usefull Blogs For You. <a>View More</a></p>
            </div> -->
        </div>
    </section>

    <!-- contact us -->
    <section id="contact" class="home-contact-area home-2-contact ptb-100">
        <div class="container">
            <div class="section-title">
                <span>Contact Us</span>
                <h2>Let Us Know About Your Project Idea!</h2>
                <p>Reach out to us for personalized support, quick answers, and
                    expert guidance—our team is ready to assist you
                    promptly.</p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <form (ngSubmit)="onSubmit()" id="contactForm">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name"
                                            class="form-control" required
                                            placeholder="Your Name" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="email" name="email"
                                            id="email" class="form-control"
                                            required placeholder="Your Email" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" name="phone_number"
                                            id="phone_number" required
                                            class="form-control"
                                            placeholder="Your Phone" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject"
                                            id="msg_subject"
                                            class="form-control" required
                                            placeholder="Subject" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message"
                                            class="form-control" id="message"
                                            cols="30" rows="5" required
                                            placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <re-captcha
                                        siteKey="6LeBxkYqAAAAAGXRXF1Mp8V2N8l-Ri_wiM1e6O2H"
                                        (resolved)="onCaptchaResolved($event)"></re-captcha>
                                </div>
                                <div class="col-lg-12 col-md-12 text-left">
                                    <button type="submit"
                                        class="default-btn page-btn box-btn">Submit</button>
                                </div>
                                <div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img contact-img-2 rounded">
                        <img src="assets/images/contact-img2.jpg"
                            alt="contact" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

<!-- footer section -->
<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a href="#home"
                            (click)="scrollToSection($event, 'home')"><img
                                src="../assets/images/compract-logo-edited.png"
                                style="height: 65px; width:150px; "
                                alt="logo" /></a>
                    </div>
                    <p>Greetings and welcome to our Web Development Agency! At
                        Compract solutions are thrilled to have you here.</p>

                    <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control"
                                placeholder="Your Email" name="EMAIL" required
                                autocomplete="off">
                            <button class="box-btn"
                                type="submit">Subscribe</button>
                        </form>
                    </div>
                    <!-- <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest' ></i></a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Pages</h3>
                    <ul class="footer-list">
                        <li><a href="#home"
                                (click)="scrollToSection($event, 'home')">Home</a></li>
                        <li><a href="#about"
                                (click)="scrollToSection($event, 'about')">About</a></li>
                        <li><a href="#services"
                                (click)="scrollToSection($event, 'services')">Service</a></li>
                        <li><a href="#blog"
                                (click)="scrollToSection($event, 'blog')">Blog</a></li>
                        <li><a href="#contact"
                                (click)="scrollToSection($event, 'contact')">Conatct</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Our Service</h3>
                    <ul class="footer-list">
                        <li><a routerLink="/">Visual Design</a></li>
                        <li><a routerLink="/"> Development</a></li>
                        <li><a routerLink="/">QA & Testing</a></li>
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Cyber Security</a></li>
                        <li><a routerLink="/">Wireless Connection</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>
                    <ul class="footer-list foot-social">
                        <li><a href="tel:+601111773687"><i
                                    class="bx bx-mobile-alt"></i> +6018 6655 744 </a></li>
                        <li><a
                                href="mailto:contact&#64;compractsolutions.com"><i
                                    class="bx bxs-envelope"></i>contact&#64;compractsolutions.com</a></li>
                        <li><i class="bx bxs-map"></i>Malaysia: No. 20, Jalan
                            Usahawan 6, Setapak
                            <br>53300 Kuala Lumpur,Malaysia</li>
                        <li><i class="bx bxs-map"></i> India Address: <a
                                href="https://bhojsoft.com/" target="_blank"
                                style="text-decoration: underline;">Bhojsoft
                                Solutions</a> <br> Office No: 4 Dodke Platinum
                            Behind RMD Shingad College Warje Pune - 411058</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text text-center">
                    <p style="font-size: 14px;">© copyright 2024 by <a
                            style="font-size: 14px;" href
                            target="_blank">Compract Solutions (003645203-K)</a></p>
                    <p style="font-size: 11px;">Designed and Developed by <a
                            style="font-size: 11px;"
                            href="https://bhojsoft.com/" target="_blank">
                            BhojSoft Solutions</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
