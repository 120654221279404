import { Component, OnInit, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class HomeTwoComponent implements OnInit {

  // captcha
  recaptchaResponse: string | null = null;

  onCaptchaResolved(response: string) {
    this.recaptchaResponse = response;
    // Use this.recaptchaResponse for form submission or other logic
    console.log('reCAPTCHA resolved with response:', this.recaptchaResponse);
  }

  onSubmit() {
    if (this.recaptchaResponse) {
      // Proceed with form submission or other logic
      console.log('Form submitted with reCAPTCHA response:', this.recaptchaResponse);
    } else {
      // Handle the case where reCAPTCHA was not resolved
      console.error('reCAPTCHA not resolved');
    }
  }

  // scroll pages in click on nav

  scrollToSection(event: Event, sectionId: string) {
    event.preventDefault(); // Prevent the default link click behavior

    const section = document.getElementById(sectionId);
    
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the section
    }
  }
  

  // Navbar
  locationUrl: string = '';
  navbarClass: string = 'navbar-area';
  classApplied: boolean = false;

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.locationUrl = this.router.url;
        this.navbarClass = this.locationUrl === '/home-three' ? 'navbar-area three' : 'navbar-area';
      }
    });
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  // Navbar Sticky
  isSticky: boolean = false;
  
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isSticky = scrollPosition >= 50;
  }

  ngOnInit(): void {}

  // Owl Carousel options
  homeSlides: OwlOptions = {
    animateOut: 'slideOutDown',
    animateIn: 'slideInDown',
    items: 1,
    loop: true,
    autoplay: true,
    dots: false,
    nav: true,
    autoHeight: true,
    autoplaySpeed: 800,
    mouseDrag: false,
    autoplayHoverPause: true,
    navText: [
      "<i class='flaticon-left-arrow'></i>",
      "<i class='flaticon-next-1'></i>"
    ],
    responsive: {
      0: { items: 1 },
      576: { items: 1 },
      768: { items: 1 },
      1200: { items: 1 }
    }
  }

  teamSlides: OwlOptions = {
    loop: true,
    margin: 20,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1 },
      575: { items: 2 },
      576: { items: 2 },
      768: { items: 3 },
      992: { items: 3 },
      1200: { items: 4 }
    }
  }

  clientWrap: OwlOptions = {
    loop: true,
    margin: 30,
    nav: false,
    mouseDrag: true,
    items: 1,
    dots: false,
    autoHeight: true,
    autoplay: true,
    smartSpeed: 800,
    autoplayHoverPause: true,
    responsive: {
      0: { items: 1, margin: 10 },
      576: { items: 1 },
      768: { items: 2, margin: 20 },
      992: { items: 2 },
      1200: { items: 2 }
    }
  }

  // Tabs
  currentTab = 'tab1';
  
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }

  // Video Popup
  isOpen = false;

  openPopup(): void {
    this.isOpen = true;
  }

  closePopup(): void {
    this.isOpen = false;
  }

}
